<template>

  <div>

    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Search -->
        <b-col cols="12">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <p class="search-label">Search</p>
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search"
              />
            </div>
            <b-button
              variant="primary"
              :to="{ name: 'user-create' }"
            >
              <span class="text-nowrap">Add User</span>
              <feather-icon
                icon="UserPlusIcon"
                size="16"
                class="add-user-icon"
              />
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        ref="refUserListTable"
        class="position-relative mb-0"
        primary-key="id"
        responsive
        show-empty
        empty-text="No matching records found"
        :items="fetchUsers"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :current-page="currentPage"
        :per-page="perPage"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(image)="data">
          <b-img
            v-if="data.item.avatarUrl && data.item.avatarUrl !== 'null'"
            :src="data.item.avatarUrl"
            width="32px"
            height="32px"
            class="rounded-full"
          />
          <b-avatar
            v-else
            size="32"
            :text="avatarText(data.item.firstname)"
            :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
          />
        </template>

        <!-- Column: fullname -->
        <template #cell(fullname)="data">
          <span>{{ `${data.item.firstname} ${data.item.lastname}` }}</span>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :disabled="data.item.email === 'admin@akarahospitality.com'"
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <div class="action-button">
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </div>
              </template>
              <b-dropdown-item :to="{ name: 'user-edit', params: { id: data.item.id } }">
                <span class="align-middle ml-50">View &amp; Edit</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="confirmDelete(data.item.id, `${data.item.firstname} ${data.item.lastname}`)"
              >
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item
                v-if="data.item.status === 'pending'"
                @click="() => makeResendInvitationToast(data.item.id, data.item.email)"
              >
                <feather-icon icon="MailIcon" />
                <span class="align-middle ml-50">Resend Invitation</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-else
                @click="() => makeResetPasswordToast(data.item.id, data.item.email)"
              >
                <feather-icon icon="RotateCcwIcon" />
                <span class="align-middle ml-50">Reset Password</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

      </b-table>
    </b-card>

    <!-- Pagination -->
    <div class="mt-2 mx-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing
            </span>
            <b-form-select
              v-model="perPage"
              :options="perPageOptions"
              class="mx-1"
            />
            <span class="text-nowrap"> of {{ dataMeta.of }} entries </span>
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalUsers"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BImg, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownDivider, BDropdownItem, BFormSelect, BPagination } from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useUsersList from './useUsersList'
import userStoreModule from '../userStoreModule'

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BFormSelect,
    BPagination,

    vSelect,
  },
  methods: {
    confirmDelete(id, fullName) {
      this.$swal({
        title: 'Are you sure to delete account?',
        text: 'This will permanently erase your account. You can’t undo action.',
        icon: 'warning',
        iconColor: '#FF9F43',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          htmlContainer: 'my-50',
          actions: 'flex-row-reverse my-1',
          confirmButton: 'btn btn-danger px-5',
          cancelButton: 'btn btn-outline-secondary px-5 mr-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (!result.value) return

        const { status } = await this.deleteUser(id);
        if (status !== 200) {
          this.makeToast('Delete failed!', `Can not delete user.`);
          return;
        }

        setTimeout(() => this.$router.go(0), 300);
        this.makeToast(
          'Delete Succeeded!',
          `User has been deleted “${fullName}”.`,
        );
      })
    },
    async makeResetPasswordToast(id, email) {
      const { status } = await this.resetPassword({ id, email });
      if (status !== 200) {
        this.makeToast(
          'Send email failed!', 
          `Can not send email to user.`,
        );
        return;
      }

      this.makeToast(
        'Reset password succeeded!',
        'Please check the email.',
      )
    },
    async makeResendInvitationToast(id, email) {
      const { status } = await this.setPassword({ id, email });
      if (status !== 200) {
        this.makeToast(
          'Send email failed!',
          `Can not send email to user.`,
        );
        return;
      }

      this.makeToast(
        'Resend invitation succeeded!',
        'Please check the email.',
      )
    },
    makeToast(title, message) {
      this.$bvToast.toast(
        (
          <div class="d-flex">
            <feather-icon
              class="text-success"
              icon="CheckCircleIcon"
              size="24"
            />
            <div class="d-flex flex-column">
              <h4 class="ml-1 text-success">{title}</h4>
              <div class="ml-1 font-14px">{message}</div>
            </div>
          </div>
        ),
        {
          headerClass: 'd-none',
          solid: true,
        },
      )
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      fetchUsers,
      setPassword,
      resetPassword,
      deleteUser,

      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
    } = useUsersList()

    return {
      // method
      fetchUsers,
      setPassword,
      resetPassword,
      deleteUser,

      // data
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.search-label {
  margin: 0 10px 0 0;
}
.add-user-icon {
  margin-left: 8px;
}
.action-button {
  border: 1px solid #6e6b7b;
  border-radius: 50%;
  padding: 5px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
